<template>
  <div>
      <h1>This is API Invoice component</h1>
  </div>
</template>

<script>
export default {
    name:'Invoice'
}
</script>

<style>

</style>